import React, { useState, useEffect } from "react";
import { ArrowRight, PlusCircle } from "lucide-react";
import InfoModal from "./components/infoModal";
import config from "../../config";

function Photos() {
  const [error, setError] = useState("");
  const initialImageCount = localStorage.getItem("ImageCount") || 0;
  const name = localStorage.getItem("Name");
  const id = localStorage.getItem("Id");
  const [imageCount, setImageCount] = useState(Number(initialImageCount));
  const [uploading, setUploading] = useState(false);


  useEffect(() => {
    localStorage.setItem("ImageCount", imageCount);
  }, [imageCount]);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const handleUpload = async (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    setUploading(true);
    setError("");
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("name", name);
    formData.append("id", id);
    try {
      const response = await fetch(
        `https://${config.subdomain}.personaliziranipokloni.com/upload.php`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        setImageCount((prevCount) => prevCount + 1);
      } else {
        setError("Došlo je do pogreške prilikom uploada fotografije.");
      }
    } catch (error) {
      setError("Došlo je do pogreške prilikom slanja zahtjeva.");
    }

    setUploading(false);
  };

  return (
    <div className="w-screen h-[93vh] overflow-hidden text-white font-della p-4 relative">
      <InfoModal />

      <div className="absolute top-4 right-4">
        <h2 className="text-2xl">{imageCount}/{config.maxImage}</h2>
      </div>

      <div className="w-full h-full flex-col gap-4 flex items-center justify-center p-5">
        {imageCount < config.maxImage ? (
          <>
            <label htmlFor="photos">
              <PlusCircle className="h-36 w-36 cursor-pointer" />
            </label>
            <input
              id="photos"
              className="hidden"
              type="file"
              accept="image/*"
              capture="user"
              onChange={handleUpload}
              disabled={uploading}
            />
            {error && <p className="text-red-500">{error}</p>}

            {uploading && <p>Učitavanje...</p>}
          </>
        ) : (
          <h1 className="text-3xl pb-10 text-center">
            Objavili ste maksimalan broj fotografija!
          </h1>
        )}


      </div>

      {!uploading && imageCount > 0 &&
        <a href="/viewPhotosGuest/">
          <div className="text-elegance p-3 bg-white w-full flex justify-end gap-4 absolute bottom-0 left-0">
            <p>Pregledaj fotografije</p>
            <ArrowRight />
          </div>
        </a>
      }
    </div>
  );
}

export default Photos;
