
const config = {
    name: 'Valentina I Frano',
    date: '29.06.2024.',
    subdomain: 'valentinaifrano29062024',
    password: 'valentina',
    maxImage: 5
  };
  
  export default config;
  