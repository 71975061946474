import React, { useState, useEffect } from "react";
import ImageCard from "./components/imageCard";
import ImageViewer from "./components/ImageViewer";
import PasswordInput from "./components/passwordInput";
import StatisticModal from "./components/StatisticModal";
import config from "../../config";

function ViewPhotos() {
  const [imageNames, setImageNames] = useState([]);
  const [showViewer, setShowViewer] = useState(false);
  const [startImageIndex, setStartImageIndex] = useState(0);
  const isAuthenticated = localStorage.getItem("authenticated") === "true";


  const handleImageClick = (index) => {
    window.scroll(0, 1000);
    setStartImageIndex(index);
    setShowViewer(true);
  };

  useEffect(() => {
    fetch(
      `https://${config.subdomain}.personaliziranipokloni.com/getImages.php`
    )
      .then((response) => response.json())
      .then((data) => setImageNames(data.reverse()))
      .catch((error) => {
        console.error("Dogodila se greška:", error);
      });
  }, []);

  const imageBaseUrl =
    `https://${config.subdomain}.personaliziranipokloni.com/uploads/`

  const imageUrls = imageNames.map((imageName) => imageBaseUrl + imageName);

  return (
    <div className="bg-elegance p-4 min-h-screen relative">
      {isAuthenticated ? (
        <>
          <StatisticModal imageUrls={imageUrls} />
          <ImageViewer
            startImgIndex={startImageIndex}
            imageUrls={imageUrls}
            showImage={showViewer}
            onClose={() => setShowViewer(false)}
          />
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 pt-10">
            {imageNames.map((imageName, index) => (
              <ImageCard
                key={index}
                name={imageName.split("-")[2]}
                time={imageName.split("-")[0]}
                onClick={() => handleImageClick(index)}
                src={`${imageBaseUrl}${imageName}`}
              />
            ))}
          </div>
          {imageNames.length === 0 && (
            <div className="h-full absolute top-0 left-0 w-full bg-elegance flex items-center justify-center text-white">
              <h1 className="text-3xl text-center">
                Još uvijek nije objavljena nijedna fotografija!
              </h1>
            </div>
          )}
        </>
      ) : (
        <PasswordInput />
      )}
    </div>
  );
}

export default ViewPhotos;
