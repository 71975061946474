import React from 'react';

const ImageCard = ({ src, name, time, onClick, cardKey }) => (
    <div className='bg-white p-2 relative h-min' onClick={onClick} key={cardKey}>
        <img loading='lazy' src={src} alt={`${name} -  ${time} `} />
        <p className='font-della text-xl text-center pt-2'>{name}</p>
        <p className='font-della text-md text-center absolute top-0 right-0 bg-elegance text-white'>{time}</p>
    </div>
)

export default ImageCard;
