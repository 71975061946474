import React, { useEffect } from "react";
import "./App.css";

import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Photos from "./pages/photos/Photos";
import View from "./pages/viewPhotos/ViewPhotos";
import ViewPhotosGuest from "./pages/viewPhotosGuest/ViewPhotosGuest";

function App() {
  const navigate = useNavigate();



  useEffect(() => {

    const id = localStorage.getItem("Id");
    const currentPath = window.location.pathname;

    if ((currentPath === "/uploadPhotos" || currentPath === "/viewPhotosGuest") && !id) {
      navigate("/");
    } else if (currentPath === "/" && id) {
      navigate("/uploadPhotos");
    }
  }, []);

  return (
    <div className="bg-elegance relative">

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/uploadPhotos" element={<Photos />} />
        <Route path="/viewPhotos" element={<View />} />
        <Route path="/viewPhotosGuest" element={<ViewPhotosGuest />} />
      </Routes>
    </div>
  );
}

export default App;
