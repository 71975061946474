import React, { useState } from "react";
import config from "../../../config";

function PasswordInput() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");


  const handlePasswordSubmit = () => {
    if (password === config.password) {
      localStorage.setItem("authenticated", "true");
      window.location.reload();
    } else {
      setError("Pogrešna lozinka. Pokušajte ponovo.");
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="h-screen w-full flex flex-col gap-4 items-center justify-center">
      <input
        type="password"
        placeholder="Unesite lozinku"
        value={password}
        className="p-2 w-full rounded-md text-elegance text-center"
        onChange={handlePasswordChange}
      />
      <button className="w-full p-2  rounded-md border border-white text-white " onClick={handlePasswordSubmit}>Pristupi</button>
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
}

export default PasswordInput;
